import React from "react"
import { Link } from "gatsby"
import JsxParser from 'react-jsx-parser'
import { AwesomeButton } from "react-awesome-button";
import { navigate } from 'gatsby'
import LayoutService from "../components/layoutservices"
import { Container, Row, Col } from 'reactstrap'
import Helmet from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';

const SklepyInternetowe = ({ data }) => (
 <>
 <Helmet
      title={data.wordpressPage.acf.oferta_title_seo} 
      titleTemplate={data.wordpressPage.acf.oferta_title_seo}
      meta={[
        {
          name: `description`,
          content: data.wordpressPage.acf.oferta_description_seo, 
        },
        {
          property: `og:title`,
          content: data.wordpressPage.acf.oferta_title_seo,
        },
        {
          name: `thumbnail`,
          content: data.wordpressPage.acf.oferta_img_seo.localFile.url, 
        },
        {
          property: `og:image`,
          content: data.wordpressPage.acf.oferta_img_seo.localFile.url, 
        },
        {
          property: `og:description`,
          content: data.wordpressPage.acf.oferta_description_seo,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />


        <div className="small-banner-content"><div style={{ 
          backgroundImage: `url(${data.wordpressPage.acf.oferta_banner_img.localFile.url})`}} className="background-small"></div>
          <div className="text-on-banner">  

          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
          <h1 className='smallbanner-title'>{data.wordpressPage.acf.oferta_title}</h1>
          </ScrollAnimation>
      
        </div>
        <div className='right-bottom-curve-cont'>
          <div className="right-bottom-curve"></div>
        </div>
        </div>


         <LayoutService lang="en" pageInfo={{ pageName: "sklepy-internetowe" }}>
         <div className='oferta-details'>
        <ScrollAnimation animateOnce='true' animateIn='fadeIn' delay='400' duration='0.5'>
          <div className='main-image'>
              <img src={data.wordpressPage.acf.oferta_main_img.localFile.url} alt={data.wordpressPage.acf.oferta_main_img.acf.alt_en}></img>
          </div>
        </ScrollAnimation>
        <div className='text-content'>
          <h2 className='main-title'>{data.wordpressPage.acf.oferta_main_title}</h2>
          {/* <p className='text'>{data.wordpressPage.acf.oferta_main_text}</p> */}
          <JsxParser className='text' 
    components={{ AwesomeButton, navigate, Link }}
    jsx={data.wordpressPage.acf.oferta_main_text}
  />

          {data.wordpressPage.acf.oferta_featured_rep.map((val, index) =>
          {if(index % 2 === 0) {
            return (
              <ScrollAnimation animateOnce='true' animateIn='fadeInLeft' delay='200' duration='0.5'>
              
            <div className='featured' key={index}>
                        <Row className='clearfix'>
                          <Col md='6' className='image-col'>
                            <div className='image'>
                              <img src={val.img.localFile.url} alt={val.img.acf.alt_en}></img>
                            </div>
                          </Col>
                          <Col md='6' className='text-col'>
                            <div className='inner'>
                              <h2 className='title'>
                              {val.title}
                              </h2>
                              <p>{val.text}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      </ScrollAnimation>
            )
          } else {
            return (
              <ScrollAnimation animateOnce='true' animateIn='fadeInLeft' delay='200' duration='0.5'>
            <div className='featured-2' key={index}>
                        <Row className='clearfix'>
                        <Col md='6' className='text-col'>
                            <div className='inner'>
                              <h2 className='title'>
                              {val.title}
                              </h2>
                              <p>{val.text}</p>
                            </div>
                          </Col>
                          <Col md='6' className='image-col'>
                            <div className='image'>
                              <img src={val.img.localFile.url} alt={val.img.acf.alt_en}></img>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      </ScrollAnimation>
            )
          }})
          }

        </div>
      </div>
  </LayoutService>
  </>
)

export default SklepyInternetowe
export const query = graphql`
query MyQuerys {
  wordpressPage(title: {eq: "Sklepy internetowe EN"}) {
    acf {
      oferta_title_seo
      oferta_description_seo
      oferta_img_seo {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      oferta_banner_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      oferta_title
      oferta_main_text
      oferta_main_title
      oferta_main_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      oferta_featured_rep {
        text
        title
        img {
          localFile {
            url
          }
          acf {
            alt_en
          }
        }
      }
    }
  }
}

 `
